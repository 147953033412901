/* eslint-disable class-methods-use-this */
import i18n, { getStartingLocale } from '@/i18n';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { LanguagePayloads, Languages } from '@/model/languages';
import axiosRestService from '@/network/axiosRestService';
import BaseModule from '../module';
import { RootState, State } from '../types';

interface LanguageState extends State {
  language: string;
}

class LanguageModule extends BaseModule<LanguageState> {
  protected initialState(): LanguageState {
    return {
      language: getStartingLocale(),
    };
  }

  protected buildGettersTree(): GetterTree<LanguageState, RootState> {
    return {
      language: (state) => state.language,
    };
  }

  protected buildMutationsTree(): MutationTree<LanguageState> {
    return {
      /**
       * This function should not be called on its own. To modify the language, use
       * the according action instead (use translateTo if the user is logged in, and
       * translateToWithoutDB if you want to set the language from within the login page)
       * @param state The state of the store
       * @param lang The language that should be set as 2-letter language code
       */
      setLanguage(state, lang: string) {
        i18n.locale = lang;
        state.language = lang;
      },
      updateDatabase(state, lang: string) {
        axiosRestService
          .put('/users/setLanguage', { language: lang.toUpperCase() })
          .catch(() => console.error('The language could not be set in the backend.'));
      },
    };
  }

  protected buildActionsTree(): ActionTree<LanguageState, RootState> {
    return {
      translateTo(context, language: string) {
        context.dispatch('translateToWithoutDB', language);
        // Call the database change separately
        context.commit('updateDatabase', language);
      },
      /**
       * We need a separate function that doesn't try to update
       * the database and can be called from within the login page
       * @param context The default context
       * @param language The 2-letter language code in lower case
       */
      translateToWithoutDB(context, language: string) {
        const payload = LanguagePayloads.get(language);
        // Check if the payload is empty
        if (Object.keys(payload).length === 0) {
          return;
        }

        // To define a language where the cookie language and/or accepted language differs
        // from the 2-letter language code, add it to the special cases inside LanguagePayloads
        const { lang, cookieLang, acceptedLang } = payload;

        document.cookie = `lang=${cookieLang || lang};path=/`;
        axiosRestService.setAcceptedLanguage(acceptedLang || lang);
        context.commit('setLanguage', language);
      },
    };
  }
}

export default new LanguageModule(false);
