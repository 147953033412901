/* eslint-disable implicit-arrow-linebreak */

import RestHandler from '@/network/axiosRestService';
import { addHours, parseISO } from 'date-fns';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import i18n from '@/i18n'
import Notification from '@/model/notification';
import store from '../store';
import urls from './urls';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: urls.LOGIN,
    component: () => import(/* webpackChunkName: "login-register" */ '../views/LoginRegister.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  {
    path: urls.AUDI_OICD_LOGIN_CALLBACK,
    component: () => import(/* webpackChunkName: "login-register" */ '../views/AudiOidcLoginCallback.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  {
    path: urls.AUDI_OICD_REGISTER_CALLBACK,
    component: () => import(/* webpackChunkName: "login-register" */ '../views/AudiOidcRegisterCallback.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  {
    path: `${urls.PASSWORD_RESET}/:token`,
    component: () => import(/* webpackChunkName: "register-link" */ '../views/PasswordReset.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  {
    path: urls.REGISTER,
    component: () => import(/* webpackChunkName: "login-register" */ '../views/LoginRegister.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  {
    path: urls.FORGOT_PASSWORD,
    component: () => import(/* webpackChungName: "login-register" */ '../views/LoginRegister.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  {
    path: `${urls.REGISTER_LINK}/:token`,
    component: () => import(/* webpackChunkName: "register-link" */ '../views/RegisterLink.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  {
    path: `${urls.MIGRATION_TO_RM}/:token`,
    component: () => import(/* webpackChunkName: "migrate-to-rm" */ '../views/MigrateToRm.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  {
    path: `${urls.RESERVATIONS_REMOTE}/:token`,
    component: () =>
      import(/* webpackChunkName: "reservations-remote" */ '../views/ReservationsRemote.vue'),
    meta: {
      layout: 'full-screen-layout',
    },
  },
  // Visitor pages
  {
    name: 'AddReservation',
    path: urls.CREATE_RESERVATION,
    component: () =>
      import(/* webpackChunkName: "add-reservation" */ '../views/visitor/AddReservation.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_VIS'],
      navbar: {
        sections: ['VIS'],
        role: 'VIS',
        title: 'Create Reservation',
      },
    },
    props: (route) => ({
      reservationCreatedStartTime: route.query.reservationCreatedStartTime
        ? parseISO(route.query.reservationCreatedStartTime as string)
        : new Date(),
      reservationCreatedEndTime: route.query.reservationCreatedEndTime
        ? parseISO(route.query.reservationCreatedEndTime as string)
        : addHours(new Date(), 1),
      siteId: route.query.siteId ? Number(route.query.siteId) : 0,
    }),
  },
  {
    path: urls.RESERVED_PARKING_LOTS,
    component: () =>
      import(
        /* webpackChunkName: "reserved-parking-lots" */ '../views/visitor/ReservedParkingLots.vue'
      ),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_VIS'],
      navbar: {
        sections: ['VIS'],
        role: 'VIS',
        icon: 'mdi-parking',
        title: 'Current Reservations',
      },
    },
  },
  {
    path: urls.MY_RESERVATIONS,
    component: () =>
      import(/* webpackChunkName: "my-reservations" */ '../views/visitor/MyReservations.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_VIS'],
      navbar: {
        sections: ['VIS'],
        role: 'VIS',
        icon: 'mdi-calendar-clock',
        title: 'Reservation History',
        lastInSection: true,
      },
    },
  },

  // Also used for admin
  {
    path: urls.ANNOUNCEMENTS,
    component: () =>
      import(/* webpackChunkName: "announcements" */ '../views/admin/Announcements.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      addButtonLink: urls.ANNOUNCEMENTS,
      searchable: true,
      navbar: {
        sections: ['OVERVIEW'],
        role: 'Admin',
        icon: 'mdi-bullhorn-outline',
        title: 'Announcements',
      },
    },
  },
  {
    path: urls.PROFILE,
    component: () => import(/* webpackChunkName: "profile" */ '../views/visitor/Profile.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC'],
    },
  },
  // Reservation Manager Pages
  // Also used for admin
  {
    path: urls.SMART_REMOTE,
    component: () =>
      import(/* webpackChunkName: "smart-remote" */ '../views/reservation-manager/SmartRemote.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM', 'BASIC_RM', 'SMART_REMOTE', 'SMART_REMOTE_PLUS'],
      navbar: {
        sections: ['RM', 'OVERVIEW'],
        role: 'Admin, RM',
        icon: 'mdi-remote',
        title: 'Smart Remote',
      },
    },
  },
  {
    path: urls.RESERVATIONS,
    component: () =>
      import(/* webpackChunkName: "reservations" */ '../views/visitor/MyReservations.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_RM', 'BASIC_ADM', 'SMART_REMOTE_PLUS'],
      addButtonLink: urls.CREATE_RESERVATION,
      navbar: {
        sections: ['OVERVIEW', 'RM'],
        role: 'RM',
        icon: 'mdi-format-list-bulleted',
        title: 'Reservations',
      },
    },
  },
  {
    path: urls.EVENTS,
    component: () =>
      import(/* webpackChunkName: "events" */ '../views/reservation-manager/Events.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_RM', 'BASIC_ADM'],
      addButtonLink: urls.ADD_EVENT,
      navbar: {
        sections: ['RM', 'OVERVIEW'],
        role: 'Admin, RM',
        icon: 'mdi-calendar',
        materialIcon: 'event',
        title: 'Events',
      },
    },
    children: [
      {
        path: urls.ADD_EVENT,
        component: () =>
          import(/* webpackChunkName: "events" */ '@/components/events/AddEventForm.vue'),
        meta: {
          layout: 'navbar-layout',
          features: ['BASIC_RM', 'BASIC_ADM'],
        },
      },
      {
        path: urls.EDIT_EVENT,
        component: undefined,
        meta: {
          layout: 'navbar-layout',
          features: ['BASIC_RM', 'BASIC_ADM'],
        },
      },
    ],
  },
  {
    path: urls.USERS,
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/reservation-manager/Users.vue'),
    meta: {
      layout: 'navbar-layout',
      addButtonLink: `${urls.USERS}?q=add`,
      features: ['BASIC_RM', 'SMART_REMOTE', 'SMART_REMOTE_PLUS', 'BASIC_ADM'],
      navbar: {
        sections: ['RM', 'OVERVIEW'],
        role: 'Admin, RM',
        icon: 'mdi-account-multiple',
        title: 'Users',
        lastInSection: true,
      },
    },
  },
  {
    path: urls.CUSTOMER_API_V3,
    component: () =>
      import(/* webpackChunkName: "codes" */ '@/components/docs/customer-api-v3.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['CUSTOMER_API'],
      navbar: {
        sections: ['CUSTOMER_API'],
        role: 'RM',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: '[Deprecated] V3',
      },
    },
  },
  {
    path: urls.PREMIUM_CUSTOMER_API_V3,
    component: () =>
      import(/* webpackChunkName: "codes" */ '@/components/docs/customer-api-v3-premium.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['RESERVATION_DISPLAY'],
      navbar: {
        sections: ['CUSTOMER_API'],
        role: 'RM',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: '[Deprecated] V3 Premium',
      },
    },
  },
  {
    path: urls.CUSTOMER_API_V4,
    component: () => import('@/components/docs/customer-api-v4.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['CUSTOMER_API'],
      navbar: {
        sections: ['CUSTOMER_API'],
        role: 'RM',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: 'V4',
      },
    },
  },
  {
    path: urls.PREMIUM_CUSTOMER_API_V4,
    component: () => import('@/components/docs/customer-api-v4-premium.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['RESERVATION_DISPLAY'],
      navbar: {
        sections: ['CUSTOMER_API'],
        role: 'RM',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: 'V4 Premium',
        lastInSection: true,
      },
    },
  },
  // Support
  {
    path: urls.SUPPORT,
    component: () => import(/* webpackChunkName: "users" */ '../views/admin/Support.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_SUP', 'BASIC_ADM'],
      navbar: {
        sections: ['SUPPORT'],
        role: 'Admin, SUP',
        icon: 'mdi-face-agent',
        title: 'Support',
        lastInSection: true,
      },
    },
  },
  // Admin
  // Section OVERVIEW
  {
    path: urls.DB_ISSUES,
    component: () =>
      import(/* webpackChunkName: "database-issues" */ '../views/admin/DatabaseIssues.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      navbar: {
        sections: ['RM', 'DATABASE'],
        role: 'Admin',
        icon: 'mdi-alert',
        title: 'Database Issues',
        lastInSection: true,
      },
    },
  },
  // Section CUSTOMERS
  {
    path: urls.TENANTS,
    component: () => import(/* webpackChunkName: "tenants" */ '../views/admin/Tenants.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      searchable: true,
      navbar: {
        sections: ['CUSTOMERS'],
        role: 'Admin',
        icon: 'mdi-city',
        title: 'Tenants',
      },
    },
  },
  {
    path: urls.LOCATIONS,
    component: () => import(/* webpackChunkName: "locations" */ '../views/admin/Locations.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      searchable: true,
      navbar: {
        sections: ['CUSTOMERS'],
        role: 'Admin',
        icon: 'mdi-map-marker',
        title: 'Locations',
      },
    },
  },
  {
    path: urls.GATEWAYS,
    component: () => import(/* webpackChunkName: "gateways" */ '../views/admin/Gateways.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      searchable: true,
      navbar: {
        sections: ['CUSTOMERS'],
        role: 'Admin',
        icon: 'mdi-chip',
        title: 'Gateways',
        lastInSection: true,
      },
    },
  },
  // Section ACL
  {
    path: urls.PRODUCTS,
    component: () => import(/* webpackChunkName: "products" */ '../views/admin/Products.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      searchable: true,
      navbar: {
        sections: ['ACL'],
        role: 'Admin',
        icon: 'mdi-package',
        title: 'Products',
      },
    },
  },
  {
    path: urls.ROLES,
    component: () => import(/* webpackChunkName: "roles" */ '../views/admin/Roles.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      searchable: true,
      navbar: {
        sections: ['ACL'],
        role: 'Admin',
        icon: 'mdi-tag-multiple',
        title: 'Roles',
        lastInSection: true,
      },
    },
  },

  // Section INTERNALS
  {
    path: urls.GATEWAY_API,
    component: () => import(/* webpackChunkName: "codes" */ '@/components/docs/gateway-api.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      navbar: {
        sections: ['INTERNALS'],
        role: 'Admin',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: 'Gateway API Docs',
      },
    },
  },
  {
    path: urls.CUSTOMER_API_V3,
    component: () => import(/* webpackChunkName: "codes" */ '@/components/docs/customer-api-v3.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      navbar: {
        sections: ['INTERNALS'],
        role: 'Admin',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: '[Deprecated] V3 Documentation',
      },
    },
  },
  {
    path: urls.PREMIUM_CUSTOMER_API_V3,
    component: () => import(/* webpackChunkName: "codes" */ '@/components/docs/customer-api-v3-premium.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      navbar: {
        sections: ['INTERNALS'],
        role: 'Admin',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: '[Deprecated] V3 Premium Documentation',
      },
    },
  },
  {
    path: urls.CUSTOMER_API_V4,
    component: () => import(/* webpackChunkName: "codes" */ '@/components/docs/customer-api-v4.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      navbar: {
        sections: ['INTERNALS'],
        role: 'Admin',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: 'V4 Documentation',
      },
    },
  },
  {
    path: urls.PREMIUM_CUSTOMER_API_V4,
    component: () => import(/* webpackChunkName: "codes" */ '@/components/docs/customer-api-v4-premium.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      navbar: {
        sections: ['INTERNALS'],
        role: 'Admin',
        icon: 'mdi-file-document-outline',
        materialIcon: 'description',
        title: 'V4 Premium Documentation',
      },
    },
  },
  {
    path: urls.LOGS,
    component: () => import(/* webpackChunkName: "logs" */ '../views/admin/Logs.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      searchable: true,
      navbar: {
        sections: ['INTERNALS'],
        role: 'Admin',
        icon: 'mdi-database',
        title: 'Database Logs',
        lastInSection: true,
      },
    },
  },

  // Section Deployment
  {
    path: `${urls.GATEWAY_API_GATEWAY}/:id`,
    redirect: `${urls.GATEWAY_API_GATEWAY}/:id/info`,
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
    },
  },
  {
    path: `${urls.GATEWAY_API_GATEWAY}/:id/:page`,
    name: 'gateway', // keep this name for the path otherwise the router sometimes matches the /gateways instead of the /gateway route
    component: () =>
      import(/* webpackChunkName: "gateway-api" */ '@/views/admin/gateway-api/Gateway.vue'),
    props: true,
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
    },
  },
  {
    path: urls.GATEWAY_API_GATEWAYS,
    component: () =>
      import(/* webpackChunkName: "gateway-api" */ '../views/admin/gateway-api/Gateways.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      searchable: true,
      addButtonLink: urls.GATEWAY_API_ADD_GATEWAY,
      navbar: {
        sections: ['DEPLOYMENT'],
        role: 'Admin',
        icon: 'mdi-format-list-checkbox',
        materialIcon: 'description',
        title: 'Gateways',
      },
    },
  },
  {
    path: urls.GATEWAY_API_ADD_GATEWAY,
    component: () =>
      import(/* webpackChunkName: "gateway-api" */ '../views/admin/gateway-api/Deploy.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
    },
  },
  {
    path: urls.GATEWAY_API_LOGS,
    component: () =>
      import(/* webpackChunkName: "gateway-api" */ '@/views/admin/gateway-api/Logs.vue'),
    meta: {
      layout: 'navbar-layout',
      features: ['BASIC_ADM'],
      navbar: {
        sections: ['DEPLOYMENT'],
        role: 'Admin',
        icon: 'mdi-message-alert',
        materialIcon: 'description',
        title: 'Gateway Logs',
        lastInSection: true,
      },
    },
  },
  {
    path: urls.HOME,
    component: () => import('../views/HomePage.vue'),
    meta: {
      layout: 'navbar-layout',
    },
  },
  // This is for all the other routes that are not previously defined
  // Keep this at the END! Very important
  {
    path: urls.NOT_FOUND,
    component: () => import(/* webpackChunkName: "logs" */ '../views/PageNotFound.vue'),
    meta: {
      layout: 'fullscreen-layout',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // make sure store state has been restored from local storage before doing anything else
  await store.restored;

  // routes that can be accessed from everyone without login
  const nonLoginPaths = [
    /^\/$/,
    new RegExp(urls.TERMS_OF_SERVICE),
    new RegExp(urls.LOGIN),
    new RegExp(urls.REGISTER),
    new RegExp(urls.FORGOT_PASSWORD),
    new RegExp(`${urls.RESERVATIONS_REMOTE}/[a-zA-Z0-9]+`, 'gm'),
    new RegExp(`${urls.REGISTER_LINK}/[a-zA-Z0-9]+`, 'gm'),
    new RegExp(`${urls.MIGRATION_TO_RM}/[a-zA-Z0-9]+`, 'gm'),
    new RegExp(`${urls.PASSWORD_RESET}/[a-zA-Z0-9]+`, 'gm'),
  ];

  if (nonLoginPaths.some((pathRegex) => pathRegex.test(to.path))) {
    return next();
  }

  // If user is not logged in and trying to access a non-public page
  if (
    !store.getters.user
      || !store.getters.user.role
      || !store.getters.user.features
  ) {
    return next(urls.LOGIN);
  }

  // Admin can access everything
  if (store.getters.user.isAdmin()) {
    return next();
  }

  // If the user has not the needed features redirect him to where he came from
  // TODO give the user some kind of feedback either by passing a param to from
  // or by showing a dialog directly from here or redirect to a unauthorized page
  if (!store.getters.userHasFeatures(to?.meta?.features)) {
    return next(from.path);
  }

  return next();
});

// When the backend responds with 401
// Checks if the current page is not in the login then the following code executes
// The frontend redirects to login with the redirect parameter in the url
RestHandler.addUnauthorizedResponseHandler(async () => {
  if (router.currentRoute.path !== urls.LOGIN && router.currentRoute.path !== '/auth') {
    await router.push(`${urls.LOGIN}?redirect=${encodeURIComponent(router.currentRoute.fullPath)}`)
    console.log('login required')
    const notification = new Notification(
      i18n.t('In order to view this content, you need to be logged in!').toString(),
    )
    await store.dispatch(
      'notifications/error',
      notification,
    )
  }
});

export default router;
